import React, { useState, useEffect } from 'react';
import { DealerService } from '../../services';
import DealerTable from './DealerTable';
import NewDealer from './NewDealer';
import UpdateDealer from './UpdateDealer';

const Dealers = (props) => {
    const [dealers, setDealers] = useState(null);
    const [showNewDealer, setShowNewDealer] = useState(false);
    const [showUpdateDealer, setShowUpdateDealer] = useState(false);
    const [currentDealer, setCurrentDealer] = useState(null);
    const [fetchAdditionalMetrics, setFetchAdditionalMetrics] = useState(false);

    useEffect(() => {
        if (dealers === null) {
            if(fetchAdditionalMetrics) {
                DealerService.getAllWithAdditionalMetrics().then(data => {
                    setDealers(data);
                })
            } else {
                DealerService.getAll().then(data => {
                    setDealers(data);
                })
            }
        }
    }, [dealers, fetchAdditionalMetrics]);

    // Forces dealer table to reload with new dealer
    const forceReloadDealers = () => { setDealers(null); }
    const toggleShowNewDealer = () => setShowNewDealer(!showNewDealer);
    const toggleShowUpdateDealer = () => setShowUpdateDealer(!showUpdateDealer);
    const toggleFetchAdditionalMetrics = () => {
        if(!fetchAdditionalMetrics) {
            DealerService.getAllWithAdditionalMetrics().then(data => {
                setDealers(data);
            })
        }
        setFetchAdditionalMetrics(!fetchAdditionalMetrics);
    }

    return (
        <div id="dealers-container">
            <h1>Dealers</h1>
            <DealerTable
                dealers={dealers}
                toggleShowNewDealer={toggleShowNewDealer}
                toggleShowUpdateDealer={toggleShowUpdateDealer}
                toggleFetchAdditionalMetrics={toggleFetchAdditionalMetrics}
                fetchAdditionalMetrics={fetchAdditionalMetrics}
                setCurrentDealer={setCurrentDealer} />
            <NewDealer
                showNewDealer={showNewDealer}
                toggleShowNewDealer={toggleShowNewDealer}
                forceReloadDealers={forceReloadDealers} />
            <UpdateDealer
                showUpdateDealer={showUpdateDealer}
                currentDealer={currentDealer}
                toggleShowUpdateDealer={toggleShowUpdateDealer}
                callback={forceReloadDealers} />
        </div>
    );
}

export default Dealers;
