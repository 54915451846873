import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import EditableTextCell from './EditableTextCell';
import CurrencyField from '../../components/CurrencyField.js';
import NotifyCheckboxCell from './NotifyCheckboxCell';
import { LoanService } from '../../services';
import Badge from '@material-ui/core/Badge';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { centsToDollar, sortDollarColumn, aprToPercent, promosToChips, promoToColor } from '../../utils/formatters';
import { isHeldCell, dealerNeedsReviewCell, isConciergeCell, isCUPullCell } from '../../utils/cellRenderers';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import { FlagCell, PaymentStageCell, LoanMetricTooltip, LoanTypeIcon, DealerMetricTooltip } from '../../components';
import { useRole } from '../../contexts/RolesContext';
import Chip from '@material-ui/core/Chip';

  const tablifyPayments = payments =>
    payments == null ? null :
    payments.map(payment => {
      return [
        payment.id,
        payment.loanNumber,
        payment.dealer.number,
        payment.dealer.name,
        centsToDollar(payment.amount),
        centsToDollar(payment.feeAmount),
        payment.paymentStage,
        payment.paymentStage,
        payment.flagPayment,
        // payment.paymentType,
        payment.dealer.organization.name,
        payment.isHeld,
        payment.notify,
        payment.commentCount,
        payment.dealer.needsReview,
        payment.dealer.isManuallyFlagged,
        payment.lender.shortName,
        payment.applicant.fullName,
        payment.dealer?.isConcierge,
        payment.notes,
        payment.stagesAlreadyPaid,
        centsToDollar(payment.estimatedProjectAmount),
        centsToDollar(payment.totalLoanAmount),
        payment.termMonths,
        aprToPercent(payment.apr),
        payment.otherStagesPaidToday,
        (payment.comingOffHold ? "Yes" : "No"),
        payment.holdComment,
        payment.dealer?.manualFlaggedReason,
        payment.dealer?.totalFundedLoans || 0,
        payment.numberOfPaymentStages,
        promosToChips(payment),
        payment.projectType,
        payment.bounced,
        payment.loanType,
        payment.dealer?.maxLoanAmount,
        payment.dealer?.totalLoanAmount,
        payment.dealer?.averageLoanAmount,
        payment.dealer?.firstFundingDate,
        payment.dealer?.lastFundingDate,
        payment.dealer?.numberOfDelinquentLoans,
        payment.dealer?.numberOfOverThirtyDayDelinquentLoans,
        payment.multiplePayments
      ];
    });

  const FeesTable = ({ payments, selectedDate }) => {
      const { isDailyProcessingWrite, isAdmin } = useRole();
      const starterColumns = ['Loan ID', 'Dealer #', 'Dealer', 'Amount', 'Fee Amount', 'Payment Stage', 'Completed Payments', 'CU Pull', 'Org', 'On Hold?', 'Comments', 'Dealer Account Flag', 'Manual Flag', 'Lender', 'Notes'];
      const [visibleColumns, setVisibleColumns] = useState((sessionStorage.getItem("feesTable")) ? getTableSession("feesTable") : saveTableSession(starterColumns, "feesTable"));

      const columns = [
        {
          name: 'Payment ID',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
            name: 'Loan ID',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Loan ID') : true,
                customBodyRender: (value, tableMeta) => {
                  return <LoanMetricTooltip
                            loanId={value}
                            estimatedProjectAmount={tableMeta.rowData[20]}
                            totalLoanAmount={tableMeta.rowData[21]}
                            loanTerm={tableMeta.rowData[22]}
                            loanApr={tableMeta.rowData[23]}
                            promos={tableMeta.rowData[30]}
                            projectType={tableMeta.rowData[31]}
                          />
                }
            }
        },
        {
          name: 'Dealer #',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Dealer #') : true,
            customBodyRender: (value, tableMeta) => {
              return <a href={`/dealers/${value}`}>{value}</a>
            }
          }
        },
        {
          name: 'Dealer',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer') : true,
            customBodyRender: (value, tableMeta) => {
                return <DealerMetricTooltip 
                          dealerName={value}
                          totalFundedLoans={tableMeta.rowData[28]} 
                          maxLoanAmount={centsToDollar(tableMeta.rowData[34])}
                          totalLoanAmount={centsToDollar(tableMeta.rowData[35])}
                          averageLoanAmount={centsToDollar(tableMeta.rowData[36])}
                          firstFundingDate={tableMeta.rowData[37]}
                          lastFundingDate={tableMeta.rowData[38]}
                          dealerConcierge={tableMeta.rowData[17] ? "Yes" : "No"}
                          dealerDelinquentLoans={tableMeta.rowData[38] == null ? 0 : tableMeta.rowData[39]}
                          dealerThirtyDayDelinquentLoansCount={tableMeta.rowData[40] == null ? 0 : tableMeta.rowData[40] }
                          />
            }
          }
        },
        {
          name: 'Amount',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Amount') : true,
              customBodyRender: (value, tableMeta) => {
                  const currencyCell = isDailyProcessingWrite() ? <CurrencyField value={value} id={tableMeta.rowData[0]} type={'payment'}/> : value;
                  return <Fragment> {tableMeta.rowData[32] ? '[BOUNCED]' : '' }{currencyCell} </Fragment>
              },
              sortCompare: sortDollarColumn
          }
        },
        {
          name: 'Fee Amount',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Fee Amount') : true,
              sortCompare: sortDollarColumn
          }
        },
        {
          name: 'Payment Stage',
          options: {
            display: visibleColumns ? visibleColumns.includes('Payment Stage') : true,
            customFilterListOptions: {
              render: v => `${v} payment`,
            },
          }
        },
        {
          name: 'Completed Payments',
          options: {
            display: visibleColumns ? visibleColumns.includes('Completed Payments') : true,
            customBodyRender: (value, tableMeta) => {
              return <PaymentStageCell value={value} alreadyPaidStages={tableMeta.rowData[19]} otherStagesPaidToday={tableMeta.rowData[24]} numberOfPaymentStages={tableMeta.rowData[28]} multiplePayments={tableMeta.rowData[41]} />
            },
            filter: false,
          }
        },
        {
          name: 'CU Pull',
          options: {
            display: visibleColumns ? visibleColumns.includes('CU Pull') : true,
            customBodyRender: isCUPullCell,
            customFilterListOptions: {
              render: v => v ? "CU Pull Fail" : "CU Pull Success",
            },
          }
        },
        // {
        //   name: 'Payment Type',
        // },
        {
          name: 'Org',
          options: {
            display: visibleColumns ? visibleColumns.includes('Org') : true,
          }
        },
        {
          name: 'On Hold?',
          options: {
            display: visibleColumns ? visibleColumns.includes('On Hold?') : true,
            customBodyRender: isHeldCell,
            customFilterListOptions: {
              render: v => v ? "On Hold" : "Not On Hold",
            },
          }
        },
        {
          name: 'Notify?',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Notify?') : true,
              customBodyRender: (value, tableMeta) => {
                return (
                  (isDailyProcessingWrite() || isAdmin()) ? 
                    <NotifyCheckboxCell value={value} id={tableMeta.rowData[0]} transactionType={'payment'} date={selectedDate} disableUpdates={false}/> :
                    <NotifyCheckboxCell value={value} id={tableMeta.rowData[0]} transactionType={'payment'} date={selectedDate} disableUpdates={true}/>
                  )
            },
            customFilterListOptions: {
              render: v => v ? "Notify Enabled" : "Notify Disabled",
            },
          }
        },
        {
          name: 'Comments',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Comments') : true,
              customBodyRender: (value) => {
                return (parseInt(value) === 0) ? null : (<Badge color="primary" badgeContent={value} max={9} overlap='rectangular' >
                        <ChatBubbleIcon />
                      </Badge>)
              }
          }
        },
        {
          name: 'Dealer Account Flag',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer Account Flag') : true,
            customBodyRender: dealerNeedsReviewCell,
            customFilterListOptions: {
              render: v => v ? "Dealer Flagged" : "Dealer Not Flagged",
            },
          }
        },
        {
          name: "Manual Flag",
          options: {
            display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
            customBodyRender: (value, tableMeta) => {
              return ( isDailyProcessingWrite() ? 
                <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[27]} /> :
                <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[27]} disableUpdates={true} />
              );
            },
            customFilterListOptions: {
              render: v => v ? "Manual Flagged" : "Non-Manual Flagged",
            },
          }
        },
        {
          name: 'Lender',
          options: {
            display: visibleColumns ? visibleColumns.includes('Lender') : true,
          }
        },
        {
          name: 'Borrower',
          options: {
            display: visibleColumns ? visibleColumns.includes('Borrower') : true,
          }
        },
        {
          name: 'Concierge Dealer',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
            customBodyRender: isConciergeCell,
          }
        },
        {
          name: 'Notes',
          options: {
              setCellProps: () => ({ style: { minWidth: "250px" }}),
              display: visibleColumns ? visibleColumns.includes('Notes') : true,
              customBodyRender: (value, tableMeta) => {
                return (
                  (isDailyProcessingWrite() || isAdmin()) ? 
                    <EditableTextCell 
                      value={value} 
                      id={tableMeta.rowData[0]} 
                      label={'Payment Notes'} 
                      onSave={LoanService.updatePaymentNotes} /> : value
                  )
            },
            filter: false,
          }
        },
        {
          name: 'Stages Already Paid',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'estimatedProjectAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          // This comes from loan table not FR table
          name: 'hiddenTotalLoanAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'term',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'apr',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Other Stages Paid Today',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Coming Off Hold',
          options: {
            display: visibleColumns ? visibleColumns.includes('Coming Off Hold') : true,
            customFilterListOptions: {
              render: v => v === "No" ? "Not Coming off Hold" : "Coming off Hold",
            },
          }
        },
        {
          name: 'Hold Comment',
          options: {
            display: visibleColumns ? visibleColumns.includes('Hold Comment') : true,
            filter: false,
          }
        },
        {
          name: 'Manual Flag Reason',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Manual Flag Reason') : true,
              viewColumns: true
          }
        },
        {
          name: 'Total Funded Loans',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Total Funded Loans') : true,
          }
        },
        {
          name: 'Number Of Payment Stages',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Number Of Payment Stages') : true,
          }
        },
        {
          name: 'Promos',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Promos') : true,
              customBodyRender: (value) => {
                return value.map( (val, key) => {
                    return <Chip label={val} key={key} style={{backgroundColor: promoToColor(val), color: 'white'}} />;
              });
            }
          }
        },
        {
          name: 'Project Type',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Project Type') : true,
          }
        },
        {
          name: 'Bounced',
          options: {
              filter: true,
              display: false,
              viewColumns: false,
              customFilterListOptions: {
                render: v => v ? "Bounced Payments" : "Non Bounced Payments",
              },
          }
        },
        {
          name: 'Loan Type',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Loan Type') : true,
              sort: false,
              viewColumns: true,
              filterOptions: {
                names: ['Home Improvement', 'Healthcare', 'Solar'],
              },
              customBodyRender: (value) => {
                return <LoanTypeIcon loanType={value} />
              },
          }
        },
        {
          name: 'Dealer Max Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Total Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Average Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer First Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Latest Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer 30+ Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Multiple Payments',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        }
      ];

      const options = {
        filterType: 'dropdown',
        print: false,
        rowsPerPage: 50,
        rowsPerPageOptions: [50,100,250],
        setRowProps: (row, dataIndex, rowIndex) => { 
          if (rowIndex % 2 === 0) {
            return {
              style: { background: 'snow' }
            };
          }
        },
        onTableChange: (action, tableState) => {
          switch (action) {
            case 'viewColumnsChange':
              let displayedVisibleColumns = tableState.columns.map(column => {
                if (column.display === "true") {
                  return column.name;
                } else {
                  return null;
                }
              }).filter(column => column !== null);

              if (sessionStorage.getItem("feesTable")) {
                saveTableSession(displayedVisibleColumns, "feesTable");
              }
              setVisibleColumns(displayedVisibleColumns);

              break;
            default:
              break;
          }
        },
        selectToolbarPlacement: 'none',
        download: false,
      };
      
      return payments === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
        <MUIDataTable data={tablifyPayments(payments)} columns={columns} options={options} />
        </Fragment>
      );   
  }

  export default memo(FeesTable);