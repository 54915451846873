import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DealerService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableRow from '@material-ui/core/TableRow';
import { centsToDollar, formatDate } from '../../utils/formatters';
import SendIcon from '@material-ui/icons/Send';
import Button from "@material-ui/core/Button";
import ConfirmationModal from '../../components/ConfirmationModal';
import green from '@material-ui/core/colors/green';
import VerifiedUserTwoTone from '@material-ui/icons/VerifiedUserTwoTone';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';


const styles = makeStyles((theme) => ({
    dealerTestTrxn: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    commentDate: {
        fontSize: '12px',
    },
    inputAmount: {
        marginLeft: '25px',
        marginTop: '15px'
    },
    emptyFooter: {
        fontSize: '12px',
        textAlign: 'center'
    },
    submitButton: {
        marginLeft: '10px',
        marginTop: '25px'
    },
    confirmButton: {
        backgroundColor: green[400]
    },
    submitDiv: {
        //textAlign: 'center'
    },
}));

const DealerTestTrxnLog = ({ dealerNumber }) => {
    const classes = styles();
    const [dealerTrxns, setDealerTrxns] = useState(null);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
    const [showConfirmDeletionBox, setShowConfirmDeletionBox] = useState(false);
    const [centsInput, setCentsInput] = useState(null);
    const [trxnId, setTrxnId] = useState(null);
    const { createErrorNotification } = useNotification();

    const TEST_TRANSACTION_LOWER_LIMIT = 0.01;
    const TEST_TRANSACTION_UPPER_LIMIT = 0.51;


    const toggleConfirmation = () => setShowConfirmationBox(!showConfirmationBox);
    const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);
    const toggleDeleteConfirmation = () => setShowConfirmDeletionBox(!showConfirmDeletionBox);

    const handleChange = (event) => setCentsInput(event.target.value)
    const handleTestTransactionSubmit = async (event) => {
        if (centsInput && !isNaN(centsInput) && TEST_TRANSACTION_LOWER_LIMIT <= parseFloat(centsInput) && parseFloat(centsInput) <= TEST_TRANSACTION_UPPER_LIMIT) {
            const response = await DealerService.newTestTransaction(dealerNumber, Math.round(centsInput*100));

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to create test transaction, please contact support`);
            }
        } else {
            console.log(!isNaN(centsInput))
            createErrorNotification(`Please enter a valid test transaction amount.`);
            toggleConfirmation();
        }
    }

    const handleConfirmTestTransactionClick = async (event) => {
        if (event.currentTarget.value) {
            setTrxnId(event.currentTarget.value);
            toggleConfirmConfirmation();
         }
    }
    const handleDeleteTestTransactionClick = async (event) => {
        if (event.currentTarget.value) {
            setTrxnId(event.currentTarget.value);
            toggleDeleteConfirmation();
        }
    }
    const handleDeleteTestTransaction = async (event) => {
        const testTransactions = await DealerService.deleteTestTransaction(trxnId);

        if(testTransactions.status === 200) {
            setTimeout(() => window.location.reload(), 100);
        }
        else {
            toggleDeleteConfirmation();
        }
        
    }

    const handleConfirmTestTransaction = async (event) => {

        const response = await DealerService.updateTestTransaction(trxnId, 'confirmed');
        const dealerResponse = await DealerService.updateDealerTestTransaction(dealerNumber, 'confirmed');


        if (dealerResponse.status && response.status && response.status === 200) {
            // TODO: Improve me
            setTimeout(() => window.location.reload(), 100);
        } else {
            createErrorNotification(`Failed to update test transaction, please contact support`);
            toggleConfirmation();
        }
    }
    


    useEffect(() => {
        DealerService.getDealerTestTransactions(dealerNumber).then(data => {
            setDealerTrxns(data);
        }).catch(err => {
            console.error(`Failed to load dealer trxn history for dealer: ${dealerNumber}`, err);
            createErrorNotification(`Failed to load dealer trxn history for dealer: ${dealerNumber}`);
        });
    }, [dealerNumber, createErrorNotification]);

    return dealerTrxns === null ?
        (<h1>Loading...</h1>) : 
        <div className={classes.dealerTestTrxn}>
            <ConfirmationModal title="Confirm" message="Are you sure you want to submit test transaction?" onConfirm={handleTestTransactionSubmit} onCancel={toggleConfirmation} showConfirmationBox={showConfirmationBox}/>
            <ConfirmationModal title="Confirm" message="Are you sure you want to confirm this test transaction?" onConfirm={handleConfirmTestTransaction} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox}/>
            <ConfirmationModal title="Confirm" message="Are you sure you want to delete this test transaction?" onConfirm={handleDeleteTestTransaction} onCancel={toggleDeleteConfirmation} showConfirmationBox={showConfirmDeletionBox}/>
            <Grid item className={classes.secondRow}>
            <div className={classes.submitDiv}>
                <Fragment>
                <TextField
                    id="outlined-select-currency-native"
                    className={classes.inputAmount}
                    placeholder="0.05"
                    value={centsInput}
                    onChange={handleChange}
                    label="Amount"
                    helperText={`Must be between $${TEST_TRANSACTION_LOWER_LIMIT} - $${TEST_TRANSACTION_UPPER_LIMIT}`}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    ></TextField>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        endIcon={<SendIcon/>}
                        onClick={toggleConfirmation}
                        >Submit
                    </Button>
                    </Fragment>
                    </div>
                    {dealerTrxns ? 
                        dealerTrxns.length === 0 ? 
                            <Fragment>
                                <h3 className={classes.emptyFooter}>No Test Trxns</h3>
                            </Fragment> : 
                            <Fragment>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell align="left"><b>Date</b></TableCell>
                                            <TableCell align="left"><b>Amount</b></TableCell>
                                            <TableCell align="left"><b>Send Date</b></TableCell>
                                            <TableCell align="left"><b>Status</b></TableCell>
                                            <TableCell align="left"><b>Action</b></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {dealerTrxns.map((row) => (
                                            <TableRow>
                                                <TableCell>{formatDate(row.created)}</TableCell>
                                                <TableCell>{centsToDollar(row.amountCents)}</TableCell>
                                                <TableCell>{row.sendDate ? formatDate(row.sendDate) : null}</TableCell>
                                                <TableCell>{row.transactionStatus.toUpperCase()}</TableCell>
                                                <TableCell>{row.transactionStatus === 'sent' ? (
                                                    <Button
                                                    variant="contained"
                                                    className={classes.confirmButton}
                                                    endIcon={<VerifiedUserTwoTone/>}
                                                    value={row.id}
                                                    onClick={handleConfirmTestTransactionClick}
                                                    >Confirm
                                                </Button>
                                                ) : (row.transactionStatus === 'confirmed' ? (<Fragment><VerifiedUserTwoTone/>{formatDate(row.confirmDate)}</Fragment>) :
                                                (row.transactionStatus === 'ready to send' ? (<Button
                                                                                    variant="contained" 
                                                                                    className={classes.confirmButtonn} 
                                                                                    endIcon={<DeleteOutlineTwoToneIcon/>} 
                                                                                    value={row.id} 
                                                                                    onClick={handleDeleteTestTransactionClick}>
                                                                                        Delete
                                                                                    </Button>) : null))}</TableCell>

                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                            </Fragment> 
                        : null
                    }
                </Grid>
        </div>
}

export default DealerTestTrxnLog;
