import React, { useState } from 'react';
import { formatDate } from '../../utils/formatters';
import { numericRegex, dollarAmountRegex, isBlank, updateRequiredField, stages } from '../../utils/dailyPaymentFormHelpers';
import { LoanService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  modalButton: {
    marginLeft: '15px',
    marginTop: '15px',
    float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

const NewPayment = (props) => {
  const classes = useStyles();
  const [loanNumber, setLoanNumber] = useState(null);
  const [loanNumberError, setLoanNumberError] = useState(false);
  const [stage, setStage] = useState('unstaged');
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState(false);
  const { createErrorNotification } = useNotification();
  const resetState = () => {
    setLoanNumber(null);
    setStage(null);
    setAmount(null);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // First check for missing required fields
    let hasMissingRequiredFields = false;
    hasMissingRequiredFields = updateRequiredField(loanNumber, setLoanNumber, setLoanNumberError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(amount, setAmount, setAmountError) || hasMissingRequiredFields;
    console.log(hasMissingRequiredFields); // esLint Hack

    if (loanNumberError || isBlank(loanNumber)) {
      createErrorNotification("Invalid loan number. Must be in format '12345'");
    } else if (amountError || isBlank(amount)) {
      createErrorNotification("Invalid Amount. Must be in format '123' or '123.45");
    } else {
      // ? Note: Sent in dollars
      const response = await LoanService.newPayment({
        loanNumber: ('PP' + loanNumber),
        stage: stage,
        amount: amount,
        paymentDate: formatDate(props.selectedDate),
        type: 'nacha',
      });

      if (response.status && response.status === 200) {
        resetState();
        props.toggleShowNewPayment();
        // TODO: Improve me
        setTimeout(() => window.location.reload(), 500)
      } else {
        createErrorNotification("Failed to save new payment");
      }
    }
  }

  const handleCancel = () => {
    props.toggleShowNewPayment();
    resetState();
  }

  const handleLoanNumberChange = (event) => {
    if (numericRegex.test(event.target.value)) {
      setLoanNumberError(false);
      setLoanNumber(event.target.value);
    } else {
      setLoanNumberError(true);
    }
  }

  const handleStageChange = (event) => {
    setStage(event.target.value);
  }

  const handleAmountChange = (event) => {
    if (dollarAmountRegex.test(event.target.value)) {
      setAmountError(false);
      setAmount(event.target.value);
    } else {
      setAmountError(true);
    }
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Create Payment</h2>
      <form onSubmit={handleSubmit}>
        <TextField label="Loan Number" inputProps={{ maxLength: 10 }} InputProps={{startAdornment: <InputAdornment position="start">PP</InputAdornment>,}} autoFocus={true} required={true} error={loanNumberError} onChange={handleLoanNumberChange} />
        <TextField label="Stage" select value={stage} fullWidth={true} margin="normal" required={true} onChange={handleStageChange} >
          {stages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="Amount" inputProps={{ maxLength: 9 }} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}} fullWidth={true} margin="normal" required={true} error={amountError} onChange={handleAmountChange} />
        <Grid container spacing={5} justifyContent="flex-start">
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
            <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.showNewPayment}
        onClose={props.toggleShowNewPayment}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default NewPayment;