import React, { memo, Fragment } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';

const RepullButton = ({hasItems, pullName, onClick, selectedDate}) => {
    let selectedDateIsToday = false;
    if (selectedDate && selectedDate.constructor.name === 'Date') {
      selectedDateIsToday = selectedDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    } else {
       selectedDateIsToday = new Date().toLocaleDateString() === selectedDate
    }

    return selectedDateIsToday ? (
        <Fragment>
            <Tooltip title={`${hasItems ? 'WARNING: Force Pull': 'Pull'} ${pullName}`}>
              <IconButton onClick={onClick}>
              {
                  hasItems ?
                      <RestoreOutlinedIcon /> :
                      <RefreshOutlinedIcon />
              }
              </IconButton>
            </Tooltip>
      </Fragment>
    ) : null;
  }

export default memo(RepullButton);