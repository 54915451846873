import React, { Fragment } from 'react';
import CurrencyField from '../../components/CurrencyField';

const UpdateReturnsAmountCell = (props) => {
    if (new Date(props.returnDate).toDateString() === new Date().toDateString() && props.isDPWrite){
        const currencyCell = <CurrencyField value={props.amount} id={props.tableMeta.rowData[0]} type={'return'}/>;
        return <Fragment> {props.bounced ? '[BOUNCED]' : '' }{currencyCell} </Fragment>
    }
    return props.amount;
}

export default UpdateReturnsAmountCell;